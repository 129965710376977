import { CheckIcon } from "@heroicons/react/20/solid";

const includedFeatures = [
  "Web application or Mobile app",
  "Landingpage",
  "Payments, Authentication, Databases, Analytics",
  "SEO (Search Engine Optimization)",
];

export default function Pricing() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl sm:text-center">
          <h2 className="text-pretty text-5xl font-semibold tracking-tight text-gray-900 sm:text-balance sm:text-6xl">
            Simple no-tricks pricing
          </h2>
          <p className="mx-auto mt-6 max-w-2xl text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">
            No hidden fees, no surprises—just clear and simple pricing. You get
            everything you need without the fine print.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-3xl font-semibold tracking-tight text-gray-900">
              Fixed pricing
            </h3>
            <p className="mt-6 text-base/7 text-gray-600">
              Predictable costs for your MVP development. I offer fixed pricing
              so you know exactly what you’re paying upfront—no hourly rates, no
              unexpected charges.
            </p>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm/6 font-semibold text-highlight">
                What’s included
              </h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm/6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
              {includedFeatures.map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  <CheckIcon
                    aria-hidden="true"
                    className="h-6 w-5 flex-none text-highlight"
                  />
                  {feature}
                </li>
              ))}
            </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:shrink-0">
            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-sm font-semibold text-red-500">
                  (only 1 spot left)
                </p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-gray-500 line-through">$5.999</span>
                  <span className="text-5xl font-semibold tracking-tight text-gray-900">
                    $4,999
                  </span>
                  <span className="text-sm/6 font-semibold tracking-wide text-gray-600">
                    USD
                  </span>
                </p>

                <a
                  href="https://cal.com/fliellerjulian/30min"
                  className="mt-10 block w-full rounded-md bg-highlight px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-highlight"
                >
                  Book a call
                </a>
                <a
                  href="https://tally.so/r/31bY4W"
                  className="mt-5 block w-full rounded-md border border-highlight px-3 py-2 text-center text-sm font-semibold text-highlight hover:bg-highlight/10 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-highlight"
                >
                  Contact me
                </a>

                <p className="mt-6 text-xs/5 text-gray-600">
                  The first call is free
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { useState } from "react";

export default function Hero() {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="relative isolate px-6 pt-14 lg:px-8 bg-white">
      {" "}
      {/* Set bg-white for a fixed light background */}
      <div
        aria-hidden="true"
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#39FF14] to-[#1B740A] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
        />
      </div>
      <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
        <div className="mb-8 flex justify-center sm:mb-8 sm:justify-center">
          <div className="relative rounded-full px-3 py-1 text-sm/6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
            <a href="/portfolio" className="font-semibold text-highlight">
              <span aria-hidden="true" className="absolute inset-0" />
              My Portfolio <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </div>

        <div className="text-center">
          <h1 className="text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-7xl relative">
            Your{" "}
            <span
              className="relative underline decoration-wavy decoration-green-500 cursor-pointer"
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            >
              MVP
              {showTooltip && (
                <div className="absolute left-1/2 top-full mt-2 -translate-x-1/2 w-72 rounded-lg bg-gray-800 text-white text-sm p-4 shadow-lg z-50">
                  <p className="font-medium tracking-normal text-gray-200">
                    An MVP (Minimum Viable Product) is a streamlined version of
                    your product designed to excel at 1-2 core features. It’s
                    crafted to test market viability and gather user feedback
                    quickly.
                  </p>
                </div>
              )}
            </span>{" "}
            is just days away.
          </h1>
          <p className="mt-8 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">
            Transform your idea into a market-ready MVP in days. <br />
            Quick, budget-friendly, and worry-free.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="https://cal.com/fliellerjulian/30min"
              className="rounded-md bg-highlight px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-highlight"
            >
              Book your first call for free
            </a>
            <a
              href="https://tally.so/r/31bY4W"
              className="text-sm/6 font-semibold text-gray-900 hover:text-gray-500"
            >
              Contact me <span aria-hidden="true">→</span>
            </a>
          </div>
        </div>
      </div>
      <div
        aria-hidden="true"
        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#39FF14] to-[#1B740A] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
        />
      </div>
    </div>
  );
}
